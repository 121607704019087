h1,
p {
	margin: 0;
}

.App {
	padding: 20px;
	min-height: calc(100vh - 40px);
}

h1,
h2,
h3 {
	font-family: "Rubik Mono One", sans-serif;
}

h1 {
	font-size: 8.5em;
	color: white;

	@media (width < 500px) {
		font-size: 6em;
	}
}

h2 {
	font-size: 2.25em;
	margin: 0;

	@media (width < 500px) {
		font-size: 1.9em;
	}
}

h3 {
	font-size: 2em;

	@media (width < 500px) {
		font-size: 1.65em;
	}
}

.oddeven-about {
	display: grid;
	grid-template-columns: repeat(auto-fill, 320px);
	gap: 2rem;

	@media (width < 500px) {
		grid-template-columns: auto;
	}
}

.oddeven-footer .oddeven-paragraph {
	@media (width < 500px) {
		font-size: 1rem;
	}
}

h4,
p {
	font-family: "Rubik", sans-serif;
}

p {
	font-size: 20px;
}

a {
	color: black;
}
